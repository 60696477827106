import React from 'react';

import Booking from 'components/Booking/Booking';

// style
import './Home.scss';

// logo
import wanonLogo from 'assets/img/nav-logo.png';
import stockholmLogo from 'assets/img/stockholm-logo.svg';
// icon
import sprite from 'assets/icons/sprite.svg';

function Home() {
	return (
		<div className='home'>
			<div className='home--visual'>
				<div class='logo'>
					<img src={wanonLogo} alt='Logo' className='logo--img' />
				</div>
				<div className='home--visual-content'>
					<h4 className='heading--large text--white text--capitalize u-center-text'>
						East Africa Online Training Workshop On "Science & Environmental
						Journalism"
					</h4>
					<p className='line u-margin-bottom-medium u-margin-top-medium'></p>
					<h4 className='heading--primary text--black text--capitalize u-center-text'>
						Communicating Climate Change and the Blue Economy: What are the
						Opportunities for Storytelling?
					</h4>
					<div className='home--visual-info u-margin-top-medium'>
						<div className='info--card'>
							<svg className='info--icon'>
								<use xlinkHref={`${sprite}#${'icon-calendar'}`} />
							</svg>
							<div className='info--col'>
								<p className='info--text heading--primary text--bold text--white'>
									16TH - 18TH DECEMBER
								</p>
								<p className='info--text heading--primary text--bold text--white'>
									9AM - 4PM
								</p>
							</div>
						</div>
						<div className='info--card'>
							<svg className='info--icon'>
								<use xlinkHref={`${sprite}#${'icon-location'}`} />
							</svg>
							<div className='info--text heading--primary text--bold text--white'>
								ONLINE
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='home--action'>
				<Booking />
				<div className='home--action-container'>
					<div className='footer'>
						<h4 className='u-margin-top-small heading--primary text--grey u-center-text u-margin-bottom-medium'>
							Event Organized By:
						</h4>
						<div className='footer--flex'>
							<div className='logo'>
								<img src={wanonLogo} alt='Logo' className='logo--img' />
							</div>
							<div className='logo'>
								<img src={stockholmLogo} alt='Logo' className='logo--img' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
