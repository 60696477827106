import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import Home from 'components/Home/Home';
import Error404 from 'components/Errors/Error404';

import { history } from './history';

function Routes() {
	return (
		<Router history={history}>
			<Switch>
				<Route exact path='/' component={Home} />
				{/* Error 404 */}
				<Route exact path='/404' component={Error404} />
				<Redirect from='*' to='/404' />
			</Switch>
		</Router>
	);
}

export default Routes;
