import React, { useState } from 'react';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import Select from 'react-select';

import Rating from 'react-rating';

// Service
import { bookingHandler } from 'services/bookingService';

import './Booking.scss';

import sprite from 'assets/icons/sprite.svg';

// mailbox image
import mailboxImg from 'assets/img/mailbox.svg';

const genderOptions = [
	{ value: 'male', label: 'Male' },
	{ value: 'female', label: 'Female' },
	{ value: 'other', label: 'Prefer Not To Say' },
];

const digitalStoriesOptions = [
	{ value: 'yes', label: 'Yes' },
	{ value: 'no', label: 'No' },
];
const knowledgeNeedOptions = [
	{ value: 'yes', label: 'Yes' },
	{ value: 'no', label: 'No' },
];
const enhanceNetworkOptions = [
	{ value: 'yes', label: 'Yes' },
	{ value: 'no', label: 'No' },
];

function Booking() {
	const initialStep = { step: 1 };
	const [{ step }, setStep] = useState(initialStep);
	const clearSteps = () => {
		setStep({ ...initialStep });
	};

	// Collect all form data in a state to submit to /bookings endpoint
	const [bookingData, setBookingData] = useState({
		fullName: '',
		email: '',
		phone: '',
		gender: '',

		workCity: '',
		residenceCountry: '',
		employerName: '',
		typeOfMedia: '',

		majorEnvironmentalStory: '',
		passionCB: '',
		knowledgeNeedCB: '',
		envDigitalStories: '',
		enhanceNetwork: '',
	});

	const [selectData, setSelectData] = useState({
		gender: '',
		knowledgeNeedCB: '',
		envDigitalStories: '',
		enhanceNetwork: '',
	});

	const handleChange = (e) => {
		e.persist();

		setBookingData((bookingData) => ({
			...bookingData,
			[e.target.name]: e.target.value,
		}));
	};

	const handlePhoneInput = (phone) => {
		setBookingData((bookingData) => ({
			...bookingData,
			phone: phone,
		}));
	};

	const handleGenderSelect = (selectedGender) => {
		setSelectData((selectData) => ({
			...selectData,
			gender: selectedGender,
		}));

		setBookingData((bookingData) => ({
			...bookingData,
			gender: selectedGender.value,
		}));
	};

	const handleKnowledgeSelect = (knowledgeNeedCB) => {
		setSelectData((selectData) => ({
			...selectData,
			knowledgeNeedCB: knowledgeNeedCB,
		}));

		setBookingData((bookingData) => ({
			...bookingData,
			knowledgeNeedCB: knowledgeNeedCB.value,
		}));
	};

	const handleDigitalSelect = (selected) => {
		setSelectData((selectData) => ({
			...selectData,
			envDigitalStories: selected,
		}));

		setBookingData((bookingData) => ({
			...bookingData,
			envDigitalStories: selected.value,
		}));
	};

	const handleEnhanceNetworkSelect = (selected) => {
		setSelectData((selectData) => ({
			...selectData,
			enhanceNetwork: selected,
		}));

		setBookingData((bookingData) => ({
			...bookingData,
			enhanceNetwork: selected.value,
		}));
	};

	const handleRatingChange = (value) => {
		setBookingData((bookingData) => ({
			...bookingData,
			passionCB: value,
		}));
	};

	const handleNextClick = (e) => {
		setStep((prevStepState) => ({ ...prevStepState, step: step + 1 }));
	};

	const handlePreviousClick = (e) => {
		setStep((prevStepState) => ({ ...prevStepState, step: step - 1 }));
	};

	const handleBooking = async (e) => {
		e.preventDefault();

		try {
			const response = await bookingHandler(bookingData);
			alert('Success');
			console.log(response);

			setStep((step) => ({ step: 4 }));

			// setUserInfo(response.data.body);
			// handleNextClick();
		} catch (error) {
			if (error.response) {
				alert('An Error Occured');
				console.log(error);
				// setError(error.response.data.error);
			}
		}
	};

	const stepOne = (
		<React.Fragment>
			<div className={'form--group'}>
				<label htmlFor='fullName'>Your Full Name:</label>
				<input
					type='text'
					placeholder='John Doe'
					name='fullName'
					value={bookingData.fullName}
					onChange={handleChange}
				/>
			</div>{' '}
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='email'>Your Email:</label>
				<input
					type='email'
					placeholder='mail@example.com'
					name='email'
					value={bookingData.email}
					onChange={handleChange}
				/>
			</div>
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='phone'>Your Phone Number</label>
				<PhoneInput
					country={'ke'}
					preferredCountries={['ke', 'ug', 'tz']}
					inputClass={'input--class'}
					inputStyle={{ marginLeft: '0rem' }}
					value={bookingData.phone}
					onChange={handlePhoneInput}
				/>
			</div>
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='gender'>Gender</label>
				<Select
					options={genderOptions}
					onChange={handleGenderSelect}
					name='gender'
					value={selectData.gender}
				/>
			</div>
			<div className={'form--group'}>
				<button
					className={
						'btn btn--icon btn--icon-reverse u-margin-top-medium u-link-hover'
					}
					block='true'
					onClick={handleNextClick}>
					<svg class='btn--icon-shape'>
						<use xlinkHref={`${sprite}#${'icon-arrow-right'}`} />
					</svg>
					<span>Continue</span>
				</button>
			</div>
		</React.Fragment>
	);

	const stepTwo = (
		<React.Fragment>
			<div className={'form--group'}>
				<label htmlFor='workCity'>Your City of Work:</label>
				<input
					type='text'
					placeholder='e.g. Kigali'
					name='workCity'
					value={bookingData.workCity}
					onChange={handleChange}
				/>
			</div>{' '}
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='residenceCountry'>Country of Residence:</label>
				<input
					type='text'
					placeholder='e.g. Rwanda'
					name='residenceCountry'
					value={bookingData.residenceCountry}
					onChange={handleChange}
				/>
			</div>
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='employerName'>Your Current Employer:</label>
				<input
					type='text'
					placeholder='e.g. BBC'
					name='employerName'
					value={bookingData.employerName}
					onChange={handleChange}
				/>
			</div>
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='typeOfMedia'>
					Type of Media Working for Currently:
				</label>
				<input
					type='text'
					placeholder=''
					name='typeOfMedia'
					value={bookingData.typeOfMedia}
					onChange={handleChange}
				/>
			</div>
			<div className={'form--group form--group-flex'}>
				<button
					className={
						'btn btn--icon btn--icon-reverse u-margin-top-medium u-link-hover'
					}
					block='true'
					onClick={handlePreviousClick}>
					<svg class='btn--icon-shape'>
						<use xlinkHref={`${sprite}#${'icon-arrow-left'}`} />
					</svg>
					<span>Previous</span>
				</button>
				<button
					className={
						'btn btn--icon btn--icon-reverse u-margin-top-medium u-link-hover'
					}
					block='true'
					onClick={handleNextClick}>
					<svg class='btn--icon-shape'>
						<use xlinkHref={`${sprite}#${'icon-arrow-right'}`} />
					</svg>
					<span>Continue</span>
				</button>
			</div>
		</React.Fragment>
	);

	const stepThree = (
		<React.Fragment>
			<div className={'form--group'}>
				<label htmlFor='majorEnvironmentalStory'>
					What major environmental story, if any, have you covered?
				</label>
				<input
					type='text'
					placeholder=''
					name='majorEnvironmentalStory'
					value={bookingData.majorEnvironmentalStory}
					onChange={handleChange}
				/>
			</div>{' '}
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='passionCB'>
					How passionate are you about Climate Change or Blue Economy?
				</label>
				<Rating
					onClick={handleRatingChange}
					initialRating={bookingData.passionCB}
					emptySymbol={
						<svg class='rating--icon' pointerEvents='none'>
							<use xlinkHref={`${sprite}#${'icon-star-empty'}`} />
						</svg>
					}
					fullSymbol={
						<svg class='rating--icon' pointerEvents='none'>
							<use xlinkHref={`${sprite}#${'icon-star'}`} />
						</svg>
					}
				/>
			</div>
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='knowledgeNeedCB'>
					Do you feel the need to increase your knowledge and skills on covering
					climate change and Blue Economy?
				</label>
				<Select
					options={knowledgeNeedOptions}
					value={selectData.knowledgeNeedCB}
					onChange={handleKnowledgeSelect}
				/>
			</div>
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='envDigitalStories'>
					Are you interested in expanding your capacity to do environmental
					stories digitally?
				</label>
				<Select
					options={digitalStoriesOptions}
					value={selectData.envDigitalStories}
					onChange={handleDigitalSelect}
				/>
			</div>
			<div className={'form--group u-margin-top-small'}>
				<label htmlFor='enhanceNetwork'>
					Would you like to enhance your network of news sources and story ideas
					on Climate Change and Blue Economy?
				</label>
				<Select
					options={enhanceNetworkOptions}
					value={selectData.enhanceNetwork}
					onChange={handleEnhanceNetworkSelect}
				/>
			</div>
			<div className={'form--group form--group-flex'}>
				<button
					className={
						'btn btn--icon btn--icon-reverse u-margin-top-medium u-link-hover'
					}
					block='true'
					onClick={handlePreviousClick}>
					<svg class='btn--icon-shape'>
						<use xlinkHref={`${sprite}#${'icon-arrow-left'}`} />
					</svg>
					<span>Previous</span>
				</button>
				<button
					className={
						'btn btn--icon btn--icon-reverse u-margin-top-medium u-link-hover'
					}
					block='true'
					onClick={handleBooking}>
					<svg class='btn--icon-shape'>
						<use xlinkHref={`${sprite}#${'icon-arrow-right'}`} />
					</svg>
					<span>Complete Registration</span>
				</button>
			</div>
		</React.Fragment>
	);

	const stepFour = (
		<React.Fragment>
			<div className='confirmation'>
				<figure className='confirmation--visual'>
					<img src={mailboxImg} alt='Mailbox' className='confirmation--img' />
				</figure>
				<p className='confirmation--text text--bold text--grey u-margin-top-medium'>
					Thank you for your Registration. Check your Email For Confirmation.
				</p>
			</div>
		</React.Fragment>
	);

	return (
		<div className='booking'>
			<div className='booking--header u-margin-bottom-medium'>
				<h4 className='heading--primary text--grey u-center-text'>
					Communicating Climate Change and the Blue Economy In Partnership with{' '}
					<span className='stockholm'>
						Stockholm Environment Institute - Africa
					</span>
				</h4>
			</div>
			<React.Fragment>
				{(step === 1 || step === 2 || step === 3) && (
					<React.Fragment>
						<form className='form'>
							{step === 1 && stepOne}
							{step === 2 && stepTwo}
							{step === 3 && stepThree}
						</form>
					</React.Fragment>
				)}
				{step === 4 && stepFour}
			</React.Fragment>
		</div>
	);
}

export default Booking;
